import React from "react";
import "./QuestionListContainer.css";
import xButtonPic2 from '../../../assets/images/xButtonPic2.png'; // 상대 경로를 사용해 이미지를 import


function QuestionListContainer({
  questions,
  onSelectQuestion,
  selectedQuestionIndex,
  onAdd,
  onDelete
}) {
  return (
    <div className="question-list-container">
      <div className="question-list-title">질문 리스트</div>
      <table>
        <tbody>
          {questions.map((question, index) => (
            <tr
              className={`question-item ${
                index === selectedQuestionIndex ? "selected" : ""
              }`}
              key={index}
              >
              <td onClick={() => onSelectQuestion(index)}>
                {index + 1}. {question}
              </td>
              <td className="delete-icon-container">
                {index !== questions.length - 1 && (  // 마지막 항목이 아닐 경우에만 X 버튼을 렌더링
                  <img 
                  src={xButtonPic2} 
                  alt="xButtonPic2" 
                  className="delete-icon"  // 새로운 클래스를 추가
                  onClick={() => onDelete(index)} 
                />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="buttons-container">
        <button className="continue-btn" onClick={onAdd}>
          계속 작성하기
        </button>
      </div>
    </div>
  );
}

export default QuestionListContainer;