import React, { useState } from "react";
import DataAddContainer from "./add_data/DataAddContainer";
import DataTableContainer from "./DataTableContainer";
import DataCountContainer from "./DataCountContainer";
import TopBarContainer from "../../components/header/TopBarContainer";
import SearchBarContainer from "./SearchBarContainer";
import TagSelectorContainer from "./TagSelectorContainer";
import CustomSnackbarSuccess from "../../utils/CustomSnackbarSuccess";
import "./ManagementConsoleContainer.css";
import CostAddContainer from "./add_cost_data/CostAddContainer";

function ManagementConsoleContainer() {
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectTag, setSelectTag] = useState("");
  const menu = "에이전트 학습";
  const description = "AI 에이전트가 학습한 Q&A를 추가, 수정, 삭제하는 페이지입니다.";

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: "",
    severity: "info"
  });

  const openSnackbar = (message, severity = "info") => {
    setSnackbarInfo({
      open: true,
      message,
      severity
    });
  };

  const closeSnackbar = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      open: false
    });
  };

  const handleAdd = () => {
    setReload(!reload);
    openSnackbar("데이터 추가가 완료되었습니다.", "success");
  };

  const handleAddExcel = () => {
    setReload(!reload);
    openSnackbar("백그라운드에서 데이터 추가가 진행중입니다.", "success");
  };

  const handleUpdate = () => {
    setReload(!reload);
    openSnackbar("업데이트가 완료되었습니다.", "success"); // Snackbar를 띄웁니다.
  };

  const handleSearch = query => {
    setSearchQuery(query);
    setReload(!reload);
  };

  const handleTagSelect = tag => {
    setSelectTag(tag);
    setReload(!reload);
  };

  return (
    <div className="management-console-container">
      <TopBarContainer menu={menu} description={description} />
      <SearchBarContainer onSearch={handleSearch} />
      <div className="data-add-count-wrapper">
        <DataCountContainer
          reload={reload}
          searchQuery={searchQuery}
          selectTag={selectTag}
        />
        <TagSelectorContainer reload={reload} onTagSelect={handleTagSelect} />
        <CostAddContainer onAdd={handleAdd} onAddExcel={handleAddExcel} />
        <DataAddContainer onAdd={handleAdd} onAddExcel={handleAddExcel} />
      </div>
      <DataTableContainer
        reload={reload}
        searchQuery={searchQuery}
        selectTag={selectTag}
        onUpdate={handleUpdate}
      />
      <CustomSnackbarSuccess
        open={snackbarInfo.open}
        onClose={closeSnackbar}
        message={snackbarInfo.message}
      />
    </div>
  );
}

export default ManagementConsoleContainer;
