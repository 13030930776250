import React, { useState, useEffect } from "react"; // useEffect import 추가
import DirectAddModalView from "./DirectAddModalView";
import axios from "axios";

function DirectAddModalContainer({ show, onClose, onSaved, onImmediateClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formDataList, setFormDataList] = useState([{ question: "", answer: "", tag: [] }]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    tag: [],
  });
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);  // 초기에 0 설정

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleAdd = () => {
    // formData의 question과 answer가 둘 다 비어있지 않은 경우에만 실행
    if (formData.answer.trim() !== "") {
      let updatedFormDataList = [...formDataList];
    
      if (selectedQuestionIndex !== null) {
        // Update the existing data
        updatedFormDataList[selectedQuestionIndex] = formData;
      } else {
        // Add new data
        updatedFormDataList.push(formData);
        setOpenSnackbar(true);
      }
    
      // Remove any existing empty data (to ensure there's only one empty item at the end)
      updatedFormDataList = updatedFormDataList.filter(
        (data) => data.question.trim() !== "" || data.answer.trim() !== ""
      );
    
      // Add a new empty object at the end for the next input
      updatedFormDataList.push({ question: "", answer: "", tag: [] });
    
      setFormDataList(updatedFormDataList);
      setFormData({
        question: "",
        answer: "",
        tag: [],
      });
    
      // Use the updated list's length
      setSelectedQuestionIndex(updatedFormDataList.length - 1);
    } else {
      setOpenSnackbar(true);
    }
  };
  
  

  const handleSelectQuestion = (index) => {
    const selectedData = formDataList[index];
    setFormData(selectedData);
    setSelectedQuestionIndex(index);
  };

  const handleClose = () => {
    setFormData({
      question: "",
      answer: "",
      tag: [],
    });
    setFormDataList([{ question: "", answer: "", tag: [] }]);
  
    onClose();
  };

  const handleDelete = (indexToDelete) => {
    const updatedFormDataList = [...formDataList];
    updatedFormDataList.splice(indexToDelete, 1);
    setFormDataList(updatedFormDataList);
  
    const newSelectedIndex = updatedFormDataList.length - 1;
  
    setSelectedQuestionIndex(newSelectedIndex);
    setFormData(updatedFormDataList[newSelectedIndex] || {
      question: "",
      answer: "",
      tag: [],
    });
  };
  
  

  const handleSave = () => {
    // Ensure that both question and answer are not empty strings
    const validFormDataList = formDataList.filter(
      (data) => data.answer.trim() !== ""
    );

    // Check if current formData is valid and if so, add it to the list
    if (formData.answer.trim() !== "") {
      validFormDataList.push(formData);
    }

    // Keep track of the last index for each question
    const lastIndexMap = new Map();
    validFormDataList.forEach((data, index) => {
      lastIndexMap.set(data.question, index);
    });

    // Remove duplicates: keep only the most recent question
    const deduplicatedData = validFormDataList.filter(
      (data, index) => lastIndexMap.get(data.question) === index
    );

    setIsLoading(true);

    axios
      .post(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/add",
        JSON.stringify(deduplicatedData),
        { withCredentials: true }
      )
      .then(() => {
        setIsLoading(false);
        setFormDataList([]);
        setFormData({
          question: "",
          answer: "",
          tag: [],
        });
        onSaved();
        onImmediateClose();
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    if (show) {
      setFormData({ question: "", answer: "", tag: [] });
      setFormDataList([{ question: "", answer: "", tag: [] }]);
      setSelectedQuestionIndex(0);
    }
  }, [show]);

  if (!show) return null;

  return (
    <DirectAddModalView
      show={show}
      onClose={handleClose}
      onSaved={onSaved}
      handleAdd={handleAdd}
      handleSave={handleSave}
      handleDelete={handleDelete}
      handleSelectQuestion={handleSelectQuestion}
      formDataList={formDataList}
      formData={formData}
      selectedQuestionIndex={selectedQuestionIndex}
      setFormData={setFormData}
      isLoading={isLoading}
      openSnackbar={openSnackbar}
      handleSnackbarClose={handleSnackbarClose}
    />
  );
}

export default DirectAddModalContainer;
