import React from 'react';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import './CustomSnackbarSuccess.css';
import checkMarkWhite from "../assets/images/checkMarkWhite.png";


export default function CustomSnackbarSuccess({ open, onClose, message }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        marginLeft: '140px',
        textAlign: 'center'
      }}
    >
      <Alert
        onClose={onClose}
        severity="error"
        className="customSnackbarSuccess"
        icon={<img src={checkMarkWhite} alt="checkMarkWhite" style={{ width: '30px', height: '30px' }} />}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}




