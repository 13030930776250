import React from "react";
import "./UserName.css";
import profilePic from "../../assets/images/profilePic.svg";
import useSignout from "../../hooks/Auth/useSignout";
import logoutImg from "../../assets/images/logoutImg.svg";

function UserName({ name }) {
  const { handleSignout, error } = useSignout();

  return (
    <div className="userNameContainer">
      <img src={profilePic} alt="Profile" className="profileImage" />
      {name}
      <button onClick={handleSignout} className="signoutButton">
        <img src={logoutImg} alt="Sign Out" />
      </button>
    </div>
  );
}

export default UserName;