import React from "react";
import "./CostAddButtonView.css";

function CostAddButtonView({ onOpen }) {
  return (
    <button className="addButton" onClick={onOpen}>
      + 가격 데이터 추가하기
    </button>
  );
}

export default CostAddButtonView;
