import React, { useState, useEffect } from "react";
import axios from "axios";
import chroma from "chroma-js";
import QnAModalUpdateView from "./QnAModalUpdateView";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0, // rounding 없애기
    borderColor: "#C2D7FF", // 테두리 색상 설정
    boxShadow: "none", // 기본적인 그림자 제거
    "&:hover": {
      borderColor: "#C2D7FF",
    },
  }),

  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Pretendard",
    fontSize: "15px",
    fontWeight: 600,
    color: "#97AACF",
  }),

  menu: (provided) => ({
    ...provided,
    borderWidth: "1px",
    borderColor: "#C2D7FF",
    borderRadius: 0,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: "1px",
    maxHeight: 150,
    overflow: 'auto',
  }),

  multiValue: (provided, state) => {
    const color = state.data.color || "#F5F5F5"; // 태그의 color 속성 값을 가져오거나, 없으면 연한 회색으로 설정
    return {
      ...provided,
      backgroundColor: color,
    };
  },

  // 선택된 옵션의 스타일
  multiValueLabel: (provided) => ({
    ...provided,
    fontFamily: "Pretendard",
    fontSize: "15px",
    fontWeight: 500,
    color: "#5A6C90",
  }),

  // 드롭다운 메뉴의 옵션 스타일
  option: (provided, state) => {
    const color = chroma(state.data.color || "#FFFFFF")
      .alpha(0.5)
      .css(); // 기본값을 흰색으로 설정하고 투명도를 0.5로 설정
    return {
      ...provided,
      fontFamily: "Pretendard",
      fontSize: "15px",
      fontWeight: 500,
      color: "#5A6C90",
      ":hover": {
        backgroundColor: color, // 호버 상태에서만 태그의 색상으로 배경색 설정
      },
    };
  },

  // 실제로 입력된 값의 스타일
  input: (provided) => ({
    ...provided,
    fontFamily: "Pretendard",
    fontSize: "15px",
    fontWeight: 500,
    color: "#5A6C90",
  }),
};

function QnAModalUpdateContainer({ data, onClose, onUpdate }) {
  const [tags, setTags] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    axios
      .get(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/tag/get",
        { withCredentials: true }
      )
      .then((response) => {
        // 태그와 색상 정보를 포맷팅합니다.
        const formattedTags = response.data.tags.map((tag) => ({
          value: tag.tag_name,
          label: tag.tag_name,
          color: tag.color,
        }));
        setTags(formattedTags);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  }, []);

  const [formData, setFormData] = React.useState({
    id: data.id,
    question: data.question || "",
    answer: data.answer || "",
    tag: Array.isArray(data.tags)
      ? data.tags
      : typeof data.tags === "string"
      ? data.tags.split(",")
      : [],
  });

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTagChange = (newValue) => {
    const tags = newValue ? newValue.map((t) => t.value) : [];
    setFormData({
      ...formData,
      tag: tags,
    });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleUpdateClick = () => {
    const { question, answer } = formData;

    // 질문과 답변 둘 다 값이 있어야 함
    if (!question || !answer) {
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true);

    const endpoint =
      "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/update";

    axios
      .post(endpoint, JSON.stringify(formData), { withCredentials: true })
      .then(() => {
        setIsLoading(false);
        if (onUpdate) {
          onUpdate();
        }

        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error updating data:", error);
      });
  };

  const handleDeleteClick = () => {
    setIsLoading(true);

    const endpoint =
      "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/delete";

    axios
      .post(endpoint, JSON.stringify(formData), { withCredentials: true }) // ID를 전달하여 특정 데이터를 삭제할 수 있도록 함
      .then(() => {
        setIsLoading(false);

        if (onUpdate) {
          onUpdate(); // 삭제 후 상위 컴포넌트에 알림
        }

        onClose(); // 모달 닫기
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error deleting data:", error);
      });
  };

  return (
    <QnAModalUpdateView
      formData={formData}
      tags={tags}
      handleInputChange={handleInputChange}
      handleTagChange={handleTagChange}
      onClose={onClose}
      handleUpdateClick={handleUpdateClick}
      handleDeleteClick={handleDeleteClick}
      customStyles={customStyles}
      openSnackbar={openSnackbar}
      handleSnackbarClose={handleSnackbarClose}
      isLoading={isLoading}
    />
  );
}

export default QnAModalUpdateContainer;
