import React, { useState, useEffect } from "react";
import axios from "axios";
import CostAddButtonView from "./CostAddButtonView";
import ChoiceModalViewCost from "./ChoiceModalViewCost";
import CostExcelAddModalContainer from "./CostExcelAddModalContainer";
import styles from "./CostAddContainer.css";
import exclamationMarkDark from "../../../assets/images/exclamationMarkDark.png";

function CostAddContainer({ onAdd, onAddExcel }) {
  const [showChoiceModal, setShowChoiceModal] = useState(false);
  const [showDirectAddModal, setShowDirectAddModal] = useState(false);
  const [showCostExcelAddModal, setShowCostExcelAddModal] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false);
  const [currentOpenModal, setCurrentOpenModal] = useState(null);

  // ChoiceModal, DirectAddModal, ExcelAddModal이 열릴 때 현재 열린 모달을 설정
  const openChoiceModal = () => {
    setCurrentOpenModal("ChoiceModal");
    setShowChoiceModal(true);
  };
  const openCostExcelAddModal = () => {
    setCurrentOpenModal("CostExcelAddModal");
    setShowCostExcelAddModal(true);
  };

  const handleClose = () => {
    setShowCloseConfirmModal(true);
  };

  const handleImmediateClose = () => {
    if (currentOpenModal === "CostExcelAddModal") {
      setShowCostExcelAddModal(false);
    }
    setCurrentOpenModal(null);
  };

  const handleConfirmClose = confirm => {
    if (confirm) {
      handleImmediateClose();
    }
    setShowCloseConfirmModal(false);
  };
  useEffect(
    () => {
      axios
        .get(
          "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/tag/get",
          { withCredentials: true }
        )
        .then(response => {
          const tagsFromServer = response.data.tags;
          const formattedTags = tagsFromServer.map(tag => ({
            value: tag,
            label: tag
          }));
          setTagOptions(formattedTags);
        })
        .catch(err => {
          console.error("Error fetching tags:", err);
        });
    },
    [onAdd]
  );

  return (
    <div className="cost-add-container">
      <CostAddButtonView onOpen={openCostExcelAddModal} />
      <CostExcelAddModalContainer
        show={showCostExcelAddModal}
        // onClose={handleClose}
        onSaved={onAdd}
        onSavedExcel={onAddExcel}
        onImmediateClose={handleImmediateClose}
      />
      {showCloseConfirmModal &&
        <div className="close-confirm-modal">
          <div className="modal-content">
            <div className="modal-icon">
              <img src={exclamationMarkDark} alt="Exclamation Mark" />
            </div>
            <p className="modal-text">저장되지 않은 사항이 있습니다.</p>
            <p className="modal-text">작성을 취소하시겠습니까?</p>
            <div className="button-container">
              <button
                className="button"
                onClick={() => handleConfirmClose(false)}
              >
                아니오
              </button>
              <button
                className="button"
                onClick={() => handleConfirmClose(true)}
              >
                예
              </button>
            </div>
          </div>
        </div>}
    </div>
  );
}

export default CostAddContainer;
