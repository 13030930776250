import React, { useState, useEffect } from "react";
import axios from "axios";
import QnAInputView from "./QnAInputView";

function QnAInputContainer({
  onSave,
  tagOptions,
  formData,
  setFormData,
  selectedQuestionIndex,
  onDelete,
}) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/tag/get",
        { withCredentials: true }
      )
      .then((response) => {
        const formattedTags = response.data.tags.map((tag) => ({
          value: tag.tag_name,
          label: tag.tag_name,
          color: tag.color, // 색상 정보 추가
        }));
        setTags(formattedTags);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  }, []);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTagChange = (newValue) => {
    const tags = newValue ? newValue.map((t) => t.value) : [];
    setFormData({
      ...formData,
      tag: tags,
    });
  };

  return (
    <QnAInputView
      onSave={onSave}
      onDelete={onDelete}
      tags={tags}
      formData={formData}
      handleInputChange={handleInputChange}
      handleTagChange={handleTagChange}
      selectedQuestionIndex={selectedQuestionIndex}
    />
  );
}

export default QnAInputContainer;
