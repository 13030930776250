import React from "react";
import "./QnAModalUpdateView.css";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import xButton from "../../../assets/images/xButtonPic.svg";
import CustomSnackbarError from '../../../utils/CustomSnackbarError';
import loadingSpinner from "../../../assets/images/loadingSpinner.png";


const animatedComponents = makeAnimated();

function QnAModalUpdateView({
  formData,
  tags,
  handleInputChange,
  handleTagChange,
  onClose,
  handleUpdateClick,
  handleDeleteClick,
  customStyles,
  openSnackbar,
  handleSnackbarClose,
  isLoading,
}) {
  return (
    <div className="modal-background">
      <div className="qna-modal-update-container">
        {isLoading && (
          <div className="loading-container">
            <img src={loadingSpinner} alt="loading" className="loading-spinner" />
            <div className="loading-text">Q&A 업데이트 중입니다..</div>
          </div>
        )}
        <img
          src={xButton}
          alt="xButton"
          className="close-icon"
          onClick={onClose}
        />
        <div className="label" id="question-label">
          Q.
        </div>
        <div className="label" id="answer-label">
          A.
        </div>
        <textarea
          value={formData.question}
          onChange={(e) => handleInputChange("question", e.target.value)}
          placeholder="질문 입력하기..."
        />
        <textarea
          value={formData.answer}
          onChange={(e) => handleInputChange("answer", e.target.value)}
          placeholder="답변 입력하기..."
        />
        <div className="tag-wrapper">
          <p className="tag-title">연관 태그</p>
          <CreatableSelect
            isMulti
            components={animatedComponents}
            name="tag"
            options={tags}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleTagChange}
            value={formData.tag.map((tag) => {
              const matchingTag = tags.find((t) => t.value === tag);
              return {
                value: tag,
                label: tag,
                color: matchingTag ? matchingTag.color : undefined,
              };
            })}
            placeholder="태그 선택 및 추가하기"
            styles={customStyles}
          />
        </div>
        <div className="buttons-container">
          <button className="update-btn" onClick={handleUpdateClick}>
            수정하기
          </button>
          <button className="delete-btn" onClick={handleDeleteClick}>
            삭제하기
          </button>
        </div>
      </div>
      <div>
        <CustomSnackbarError
          open={openSnackbar}
          onClose={handleSnackbarClose}
          message="질문, 답변이 모두 입력되어야 수정이 가능합니다."
        />
      </div>
    </div>


  );
}

export default QnAModalUpdateView;
