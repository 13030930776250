import React from "react";
import "./DataAddButtonView.css";

function DataAddButtonView({ onOpen }) {
  return (
    <button className="addButton" onClick={onOpen}>
      + Q&A 추가하기
    </button>
  );
}

export default DataAddButtonView;
