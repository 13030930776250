import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTableView from "./DataTableView";
import QnAModalUpdateContainer from "./update_data/QnAModalUpdateContainer";

function DataTableContainer({ reload, searchQuery, selectTag, onUpdate }) {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;

  const currentTime = new Date().getTime();

  const fetchData = (reset = false) => {
    setIsLoading(true);

    if (reset) {
      setData([]);
      setPage(1);
    }

    const formData = {
      page: reset ? 1 : page,
      limit: limit,
      search: searchQuery,
      selectTag: selectTag,
    };

    axios
      .post(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/get",
        JSON.stringify(formData),
        { withCredentials: true }
      )
      .then((response) => {
        const processedData = response.data.map((item) => {
          const tagsArray = item.tags
            ? item.tags.split(",").map((tag) => tag.trim())
            : [];
          const colorsArray = item.colors
            ? item.colors.split(",").map((color) => color.trim())
            : [];
          const tagColorMap = tagsArray.map((tag, idx) => ({
            tag,
            color: colorsArray[idx] || "#C2D7FF",
          }));

          return {
            ...item,
            tagsArray,
            tagColorMap,
          };
        });

        if (reset) {
          setData(processedData);
        } else {
          setData((prevData) => [...prevData, ...processedData]);
        }

        setIsLoading(false);
        setHasMore(response.data.length >= limit);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  const handleRowClick = (item) => {
    setSelectedData(item);
  };

  const handleCloseModal = () => {
    setSelectedData(null);
  };

  useEffect(() => {
    if (hasMore) {
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 2
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [hasMore, page]);

  useEffect(() => {
    fetchData(true);
  }, [reload, searchQuery, selectTag]);

  useEffect(() => {
    if (page > 1) {
      fetchData();
    }
  }, [page]);

  return (
    <div>
      <DataTableView
        data={data}
        onRowClick={handleRowClick}
        currentTime={currentTime}
      />
      {isLoading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {selectedData && (
        <QnAModalUpdateContainer
          data={selectedData}
          onClose={handleCloseModal}
          onUpdate={onUpdate}
        />
      )}
    </div>
  );
}

export default DataTableContainer;
