import React from "react";
import QuestionListContainer from "./QuestionListContainer";
import QnAInputContainer from "./QnAInputContainer";
import xButton from "../../../assets/images/xButtonPic.svg";
import loadingSpinner from "../../../assets/images/loadingSpinner.png"; // 로딩 스피너 이미지 추가
import "./DirectAddModalView.css";
import CustomSnackbarError from '../../../utils/CustomSnackbarError';

function DirectAddModalView(props) {
  const {
    show,
    onClose,
    handleAdd,
    handleSave,
    handleDelete,
    handleSelectQuestion,
    formDataList,
    formData,
    selectedQuestionIndex,
    setFormData,
    isLoading,
    openSnackbar,
    handleSnackbarClose,
  } = props;

  return (
    <div className="direct-add-modal-view-background">
      <div
        className="direct-add-modal-view"
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading && (
          <div className="loading-container">
            <img src={loadingSpinner} alt="loading" className="loading-spinner" />
            <div className="loading-text">Q&A 업로드 중입니다..</div>
          </div>
        )}
        <img
          src={xButton}
          alt="xButton"
          className="close-icon"
          onClick={onClose}
        />
        <div className="question-list-container">
          <QuestionListContainer
            questions={formDataList.map((data) => data.question)}
            onSelectQuestion={handleSelectQuestion}
            selectedQuestionIndex={selectedQuestionIndex}
            onAdd={handleAdd}
            onDelete={handleDelete}
          />
        </div>
        <div className="qna-input-container">
          <QnAInputContainer
            onSave={handleSave}
            onDelete={handleDelete}
            formData={formData}
            setFormData={setFormData}
            selectedQuestionIndex={selectedQuestionIndex}
          />
        </div>
        <CustomSnackbarError
          open={openSnackbar}
          onClose={handleSnackbarClose}
          message="내용을 입력해주세요."
        />
      </div>
    </div>
  );
}

export default DirectAddModalView;
