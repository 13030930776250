import React from "react";
import "./TagSelectorView.css";

function TagSelectorView({ dropdownRef, isOpen, tags, selectedTag, onToggle, onTagClick }) {
  return (
    <div className="tag-selector-view" ref={dropdownRef}>
      <button className="tag-selector-button" onClick={onToggle}>
        {selectedTag}
        <span className="arrow">&#9662;</span>
      </button>
      {isOpen && (
        <div className="tag-list">
          {tags.map((tag) => (
            <button
              key={tag}
              className="tag-item"
              onClick={() => onTagClick(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default TagSelectorView;
