import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import ExcelAddModalView from "./ExcelAddModalView";

function ExcelAddModalContainer({
  show,
  onClose,
  onSaved,
  onImmediateClose,
  onSavedExcel
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [isProcessingInBackground, setIsProcessingInBackground] = useState(
    false
  );
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Add this useEffect
  useEffect(
    () => {
      if (!show) {
        // Modal is closed, reset the data
        setFileName(null);
        setFileData(null);
      }
    },
    [show]
  );

  if (!show) return null;

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length) {
      const file = files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "xlsx") {
        setFileName(file.name);
        readFile(file);
      } else {
        // 파일 확장자가 .xlsx가 아닌 경우
        setShowSnackbar(true);
      }
    }
  };

  const readFile = file => {
    const reader = new FileReader();
    reader.onload = e => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const qnaData = rows.slice(1).map(row => ({
        question: row[0] ? row[0].toString() : "",
        answer: row[1] ? row[1].toString() : "",
        tag: row[2] ? row[2].toString().split(",").map(tag => tag.trim()) : []
      }));

      setFileData(qnaData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUpload = () => {
    if (fileData) {
      sendDataToServer(fileData);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const sendDataToServer = qnaData => {
    setIsLoading(true);
    axios
      .post(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/add_excel",
        JSON.stringify(qnaData),
        { withCredentials: true }
      )
      .then(() => {
        setIsLoading(false);
        onSaved();
        onImmediateClose();
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response && error.response.status === 503) {
          setIsProcessingInBackground(true);
          onSavedExcel();
          onImmediateClose();
        } else {
          console.error("Error sending data:", error);
        }
      });
  };

  return (
    <ExcelAddModalView
      show={show}
      onClose={onClose}
      isDragging={isDragging}
      fileName={fileName}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleDragOver={handleDragOver}
      handleDrop={handleDrop}
      handleUpload={handleUpload}
      isProcessingInBackground={isProcessingInBackground}
      showSnackbar={showSnackbar}
      handleCloseSnackbar={handleCloseSnackbar}
      isLoading={isLoading}
    />
  );
}

export default ExcelAddModalContainer;
