import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import CostExcelAddModalView from "./CostExcelAddModalView";

function CostExcelAddModalContainer({
  show,
  onClose,
  onSaved,
  onImmediateClose,
  onSavedExcel
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [isProcessingInBackground, setIsProcessingInBackground] = useState(
    false
  );
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Add this useEffect
  useEffect(
    () => {
      if (!show) {
        // Modal is closed, reset the data
        setFileName(null);
        setFileData(null);
      }
    },
    [show]
  );

  if (!show) return null;

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length) {
      const file = files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "xlsx") {
        setFileName(file.name);
        readFile(file);
      } else {
        // 파일 확장자가 .xlsx가 아닌 경우
        setShowSnackbar(true);
      }
    }
  };

  const readFile = file => {
    const reader = new FileReader();
    reader.onload = e => {
      const base64Data = e.target.result.split(",")[1]; // Base64 encoded data
      setFileData(base64Data);
    };
    reader.readAsDataURL(file); // Read the file as a Data URL (base64 encoded)
  };

  const handleUpload = () => {
    if (fileData) {
      sendDataToServer(fileData);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const sendDataToServer = base64Data => {
    setIsLoading(true);
    axios
      .post(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/travel_learner_add_cost_data_excel",
        JSON.stringify({ fileData: base64Data, fileName: fileName }),
        { withCredentials: true }
      ) // Send both base64 data and file name
      .then(() => {
        setIsLoading(false);
        onSaved();
        onImmediateClose();
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response && error.response.status === 503) {
          setIsProcessingInBackground(true);
          onSavedExcel();
          onImmediateClose();
        } else {
          console.error("Error sending data:", error);
        }
      });
  };

  return (
    <CostExcelAddModalView
      show={show} // onClose={onClose}
      onImmediateClose={onImmediateClose}
      isDragging={isDragging}
      fileName={fileName}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleDragOver={handleDragOver}
      handleDrop={handleDrop}
      handleUpload={handleUpload}
      isProcessingInBackground={isProcessingInBackground}
      showSnackbar={showSnackbar}
      handleCloseSnackbar={handleCloseSnackbar}
      isLoading={isLoading}
    />
  );
}

export default CostExcelAddModalContainer;
