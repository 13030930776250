import React, { useState } from "react";
import SidebarContainer from "./components/sidebar/SidebarContainer";
import ManagementConsoleContainer from "./pages/management_console/ManagementConsoleContainer";
import DashboardContainer from "./pages/dashboard/DashboardContainer";
import "./AppContainer.css";

function AppContainer() {
  const [selectedMenu, setSelectedMenu] = useState("dataTable");

  return (
    <div className="appContainer">
      <SidebarContainer setSelectedMenu={setSelectedMenu} />
      {selectedMenu === "dataTable" ? (
        <ManagementConsoleContainer />
      ) : (
        <DashboardContainer />
      )}
    </div>
  );
}

export default AppContainer;
