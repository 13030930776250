// SearchBarContainer.js
import React, { useState } from "react";
import SearchBarView from "./SearchBarView";

function SearchBarContainer({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); //페이지 새로고침 방지(form의 경우 제출하면 페이지가 새로고침되는 것이 기본 동작인데 이를 중단한다.)
    onSearch(searchQuery);
  };

  return (
    <SearchBarView
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      onSearchSubmit={handleSearchSubmit}
    />
  );
}

export default SearchBarContainer;
