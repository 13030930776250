import React from "react";
import "./CompanyName.css";
import { useNavigate } from "react-router-dom";

function CompanyName() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="company-name" onClick={handleClick}>
      Generative Lab
    </div>
  );
}

export default CompanyName;
