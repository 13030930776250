import React from "react";
import "./ChoiceModalViewCost.css";
import wordImg from "../../../assets/images/wordImg.png";
import excelImg from "../../../assets/images/excelImg.png";
import xButtonPic from "../../../assets/images/xButtonPic.svg"; // X 버튼 이미지를 import

function ChoiceModalViewCost({
  show,
  onSelectDirect,
  onSelectExcel,
  onImmediateClose
}) {
  if (!show) return null;

  return (
    <div className="choice-modal-view-cost-background">
      <div className="choice-modal-view-cost">
        <div className="choice-modal-view-cost-header">
          가격 데이터 추가하기
          <div className="close-button" onClick={onImmediateClose}>
            <img src={xButtonPic} alt="Close" />
          </div>
        </div>
        <div className="button-group">
          <div className="choice-modal-button" onClick={onSelectExcel}>
            <img src={excelImg} alt="Excel" className="button-image" />
            엑셀로 추가하기
            <p className="button-description">엑셀 파일에 있는 Q&A 데이터를 업로드합니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoiceModalViewCost;
