import React, { useState, useEffect } from "react";
import axios from "axios";
import DataCountView from "./DataCountView";

function DataCountContainer({ reload, searchQuery, selectTag }) {
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const formData = {
    search: searchQuery,
    selectTag: selectTag,
  };

  useEffect(() => {

    axios
      .post(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/count/total",
        JSON.stringify(formData),
        { withCredentials: true }
      )
      .then((response) => {
        setTotalCount(response.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, [reload, searchQuery, selectTag]);

  return (
    <DataCountView
      totalCount={totalCount}
      isLoading={isLoading}
      error={error}
    />
  );
}

export default DataCountContainer;
