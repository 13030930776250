export function timeDifference(current, previous) {
    const currentTime = current;
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
  
    const elapsed = current - previous;
  
    if (elapsed < msPerMinute) {
         return Math.floor(elapsed/1000) + '초 전';   
    }
    else if (elapsed < msPerHour) {
         return Math.floor(elapsed/msPerMinute) + '분 전';   
    }
    else if (elapsed < msPerDay) {
         return Math.floor(elapsed/msPerHour) + '시간 전';   
    }
    else if (elapsed < msPerMonth) {
         return Math.floor(elapsed/msPerDay) + '일 전';  
    }
    else {
         return Math.floor(elapsed/msPerMonth) + '개월 전';   
    }
}