import React from "react";
import "./DataTableView.css";
import { timeDifference } from "../../utils/timeDifference.js";

function DataTableView({ data, onRowClick, currentTime }) {
  return (
    <table className="dataTable">
      <thead>
        <tr>
          <th>질문</th>
          <th>답변</th>
          <th>태그</th>
          <th>시간</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} onClick={() => onRowClick(item)}>
            <td>{item.question}</td>
            <td>{item.answer}</td>
            <td className="tag-container">
              {item.tagColorMap.map(({ tag, color }) => (
                <div
                  className="tag-item"
                  key={tag}
                  style={{ backgroundColor: color }}
                >
                  {tag}
                </div>
              ))}
            </td>
            <td>
              {timeDifference(
                currentTime,
                new Date(item.time).getTime() + 9 * 60 * 60 * 1000
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DataTableView;
