import React from "react";
import { useEffect } from "react";
import AppContainer from "./AppContainer"; // AppContainer 컴포넌트를 임포트합니다.
import "./App.css"; // 필요한 경우 전역 스타일을 여기에서 적용할 수 있습니다.
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./pages/Auth/LoginPage";
import NotFoundPage from "./pages/ErrorPage/NotFoundPage";

function RedirectToAgentPage() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/agent-page");
  }, [navigate]);
  return null;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectToAgentPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/agent-page"
          element={
            <ProtectedRoute>
              <AppContainer />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
