import React, { useState, useEffect } from "react";
import CompanyName from "./CompanyName";
import MenuItem from "./MenuItem";
import UserName from "./UserName"; // 이 부분을 추가합니다.
import penPic from "../../assets/images/penPic.png";
import graphPic from "../../assets/images/graphPic.svg";
import penPicDark from "../../assets/images/penPicDark.svg";
import graphPicDark from "../../assets/images/graphPicDark.svg";
import "./SidebarContainer.css";
import getUserData from "../../utils/getUserData";

function SidebarContainer({ setSelectedMenu }) {
  const [selectedItem, setSelectedItem] = useState("dataTable"); // 현재 선택된 메뉴 항목 상태
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUserData();
      setUserName(result.userName);
    };

    fetchData();
  }, []);

  const handleMenuClick = menu => {
    setSelectedItem(menu);
    setSelectedMenu(menu);
  };

  return (
    <div className="sidebarContainerContent">
      <CompanyName />
      {/* <MenuItem
        text="데이터 대시보드"
        onClick={() => handleMenuClick("dashboard")}
        isActive={selectedItem === "dashboard"}
        icon={graphPic}
        darkIcon={graphPicDark}
      /> */}
      <MenuItem
        text="에이전트 학습"
        onClick={() => handleMenuClick("dataTable")}
        isActive={selectedItem === "dataTable"}
        icon={penPic}
        darkIcon={penPicDark}
      />
      <UserName name={userName} />
    </div>
  );
}

export default SidebarContainer;
