import React from "react";
import "./MenuItem.css";

function MenuItem({ text, onClick, isActive, icon, darkIcon }) {
  return (
    <div
      className={`menuItem ${isActive ? "activeMenuItem" : ""}`}
      onClick={onClick}
    >
      <img
        src={isActive ? icon : darkIcon}
        alt="Menu Icon"
        className="menuIcon"
      />
      {text}
    </div>
  );
}

export default MenuItem;
