import React from "react";
import xButton from "../../../assets/images/xButtonPic.svg";
import excel2Img from "../../../assets/images/excel2Img.svg";
import excelExample from "../../../assets/images/excelExample.png";
import "./ExcelAddModalView.css";
import CustomSnackbarError from '../../../utils/CustomSnackbarError';
import loadingSpinner from "../../../assets/images/loadingSpinner.png";



function ExcelAddModalView({
  show,
  onClose,
  isDragging,
  fileName,
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
  handleUpload,
  showSnackbar,
  handleCloseSnackbar,
  isLoading,
}) {
  return (
    <div className="modal-background">
      <CustomSnackbarError
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        message="xlsx 형식의 파일만 업로드해주세요."
      />
      <div className="excel-add-modal-view" onClick={(e) => e.stopPropagation()}>
        {isLoading && (
          <div className="loading-container">
            <img src={loadingSpinner} alt="loading" className="loading-spinner" />
            <div className="loading-text">엑셀 데이터를 서버로 보내는 중입니다...</div>
          </div>
        )}
        <img src={xButton} alt="xButton" className="close-icon" onClick={onClose} />
        <div className="excel-header">
          <p>Q&A 추가하기</p>
        </div>
        <div className="excel-body">
          <div
            className={`upload-excel-file ${isDragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <img src={excel2Img} alt="excel2Img" />
            {fileName ? (
              <div className="selected-file">선택된 파일: {fileName}</div>
            ) : (
              <p>업로드할 파일을 드래그해주세요</p>
            )}
            {fileName && <button className="upload-button" onClick={handleUpload}>전송하기</button>}
          </div>
          
          <div className="description-excel">
            <p className="excel-title">엑셀 파일 예시</p>
            <img src={excelExample} alt="excelExample" />
            <p className="margin-side">· 업로드 버튼을 누르면 백그라운드에서 질문 업로드가 진행됩니다.</p>
            <p className="margin-side">· 질문당 1초 가량 시간이 소요됩니다.</p>
            <p className="margin-side">· 질문이 500개 이상일 경우 500개씩 나눠서 업로드해주세요.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExcelAddModalView;
