import React from "react";
import MenuName from "./MenuName";
import MenuDescription from "./MenuDescription";
import "./TopBarContainer.css";

function TopBarContainer({ menu, description }) {
  return (
    <div className="topBar">
      <MenuName menu={menu} />
      <MenuDescription description={description} />
    </div>
  );
}

export default TopBarContainer;
