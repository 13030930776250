import { useEffect } from "react";

const LoginPage = () => {
  useEffect(() => {
    const cognitoUrl =
      "https://genlab-test-domain.auth.ap-northeast-2.amazoncognito.com/login?client_id=68fbopbjo06com6h51t8d3k9v&response_type=code&scope=aws.cognito.signin.user.admin+openid&redirect_uri=https%3A%2F%2Fiz6rrfi140.execute-api.ap-northeast-2.amazonaws.com%2Fdashboard%2Fauth%2Flogin";
    window.location.href = cognitoUrl; // 현재 페이지를 Cognito 로그인 페이지로 변경합니다.
  }, []);

  return null; // 아무것도 렌더링하지 않습니다. 페이지 로딩 시 리다이렉트만 수행됩니다.
};

export default LoginPage;
