import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const host = window.location.hostname;

const logoutApiUrl = `https://genlab-test-domain.auth.ap-northeast-2.amazoncognito.com/logout?client_id=68fbopbjo06com6h51t8d3k9v&logout_uri=https://${host}/login`; // 로그아웃 API URL

const tokenExpireUrl =
  "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/auth/signout";

const signoutUser = async () => {
  try {
    const responseExpireToken = await axios.get(tokenExpireUrl, {
      withCredentials: true,
    });
    // console.log(responseExpireToken);
    // const responseSignout = await axios.get(logoutApiUrl);
    window.location.href = logoutApiUrl;
    // console.log(responseSignout);
    // return responseSignout.data;
  } catch (error) {
    throw new Error(error.response.data.error || "Failed to log out");
  }
};

const useSignout = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSignout = async () => {
    try {
      await signoutUser();
      navigate("/login"); // 로그아웃 후 로그인 페이지로 리다이렉트
    } catch (error) {
      setError(error.message);
    }
  };

  return { handleSignout, error };
};

export default useSignout;
