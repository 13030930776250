import React from "react";
import "./DataCountView.css";

function DataCountView({ totalCount, isLoading, error }) {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div className="dataCountText">질문 {totalCount}개</div>;
}

export default DataCountView;
