import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const authenticationUrl =
  "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/auth/authentication";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null: 로딩 중, true: 인증됨, false: 인증되지 않음
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuthentication = async () => {
      try {
        const response = await axios.get(authenticationUrl, {
          withCredentials: true,
        });
        // console.log(response);
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          navigate("/login");
        }
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
        navigate("/login");
      }
    };

    verifyAuthentication();
  }, [navigate]);

  // 인증 상태에 따라 컴포넌트 렌더링
  if (isAuthenticated === null) return null; // 로딩 중
  if (isAuthenticated) return children; // 인증됨
  return null; // 기본적으로는 아무것도 반환하지 않음
};

export default ProtectedRoute;
