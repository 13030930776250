import axios from "axios";

const getUserData = async () => {
  try {
    const response = await axios.get(
      "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/auth/user/profile",
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export default getUserData;
