import React from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import chroma from "chroma-js";
import "./QnAInputView.css"; // CSS 파일을 연결

const animatedComponents = makeAnimated();

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0, // rounding 없애기
    borderColor: "#C2D7FF", // 테두리 색상 설정
    boxShadow: "none", // 기본적인 그림자 제거
    "&:hover": {
      borderColor: "#C2D7FF",
    },
  }),

  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Pretendard",
    fontSize: "15px",
    fontWeight: 600,
    color: "#97AACF",
  }),

  menu: (provided) => ({
    ...provided,
    borderWidth: "1px",
    borderColor: "#C2D7FF",
    borderRadius: 0,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: "1px",
  }),

  multiValue: (provided, state) => {
    const color = state.data.color || "#F5F5F5"; // 태그의 color 속성 값을 가져오거나, 없으면 연한 회색으로 설정
    return {
      ...provided,
      backgroundColor: color,
    };
  },

  // 선택된 옵션의 스타일
  multiValueLabel: (provided) => ({
    ...provided,
    fontFamily: "Pretendard",
    fontSize: "15px",
    fontWeight: 500,
    color: "#5A6C90",
  }),

  // 드롭다운 메뉴의 옵션 스타일
  option: (provided, state) => {
    const color = chroma(state.data.color || "#FFFFFF")
      .alpha(0.5)
      .css(); // 기본값을 흰색으로 설정하고 투명도를 0.5로 설정
    return {
      ...provided,
      fontFamily: "Pretendard",
      fontSize: "15px",
      fontWeight: 500,
      color: "#5A6C90",
      ":hover": {
        backgroundColor: color, // 호버 상태에서만 태그의 색상으로 배경색 설정
      },
    };
  },

  // 실제로 입력된 값의 스타일
  input: (provided) => ({
    ...provided,
    fontFamily: "Pretendard",
    fontSize: "15px",
    fontWeight: 500,
    color: "#5A6C90",
  }),
};

function QnAInputView({
  onAdd,
  onSave,
  onDelete,
  tags,
  formData,
  handleInputChange,
  handleTagChange,
  selectedQuestionIndex,
}) {
  return (
    <div className="qna-input-view">
      <textarea
        value={formData.question}
        onChange={(e) => handleInputChange("question", e.target.value)}
        placeholder="질문 입력하기..."
      />
      <textarea
        value={formData.answer}
        onChange={(e) => handleInputChange("answer", e.target.value)}
        placeholder="답변 입력하기..."
      />
      <div className="tag-wrapper">
        <p className="tag-title">연관 태그</p>
        <CreatableSelect
          isMulti
          components={animatedComponents}
          name="tag"
          options={tags}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleTagChange}
          value={formData.tag.map((tag) => {
            const matchingTag = tags.find((t) => t.value === tag);
            return {
              value: tag,
              label: tag,
              color: matchingTag ? matchingTag.color : undefined,
            };
          })}
          placeholder="태그 선택 및 추가하기"
          styles={customStyles}
        />
      </div>
      <div className="buttons-container">
        <button className="save-btn" onClick={onSave}>
          업로드하기
        </button>
      </div>
    </div>
  );
}

export default QnAInputView;
