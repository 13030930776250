import React from "react";
import "./SearchBarView.css";

function SearchBarView({ searchQuery, onSearchChange, onSearchSubmit }) {
  return (
    <div className="searchBarWrapper">
      <form onSubmit={onSearchSubmit}>
        <input
          type="text"
          placeholder="질문 검색하기..."
          value={searchQuery}
          onChange={onSearchChange}
        />
        <button type="submit">검색</button>
      </form>
    </div>
  );
}

export default SearchBarView;
