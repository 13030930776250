import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import TagSelectorView from "./TagSelectorView";

function TagSelectorContainer({ reload, onTagSelect }) {
  const [tags, setTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState('전체 보기');
  const dropdownRef = useRef(null);

  useEffect(() => {
    // 태그 데이터를 가져오는 로직은 그대로 유지
    axios
      .get(
        "https://iz6rrfi140.execute-api.ap-northeast-2.amazonaws.com/dashboard/data/tag/get",
        { withCredentials: true }
      )
      .then((response) => {
        const fetchedTags = response.data.tags.map((tag) => tag.tag_name);
        setTags(["전체 보기", ...fetchedTags]);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
      });
  }, [reload]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleTagClick = (tag) => {
    const selected = tag === '전체 보기' ? '' : tag;
    onTagSelect(selected);
    setSelectedTag(tag); // 선택된 태그를 상태 변수에 저장
    setIsOpen(false);
  };

  return (
    <TagSelectorView
      dropdownRef={dropdownRef}
      isOpen={isOpen}
      tags={tags}
      selectedTag={selectedTag}
      onToggle={() => setIsOpen(!isOpen)}
      onTagClick={handleTagClick}
    />
  );
}

export default TagSelectorContainer;
