import React from "react";
import xButtonPic from "../../../assets/images/xButtonPic.svg";
import excel2Img from "../../../assets/images/excel2Img.svg";
import excelExample2 from "../../../assets/images/excelExample2.png";
import "./CostExcelAddModalView.css";
import CustomSnackbarError from "../../../utils/CustomSnackbarError";
import loadingSpinner from "../../../assets/images/loadingSpinner.png";

function CostExcelAddModalView({
  show,
  onImmediateClose,
  isDragging,
  fileName,
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
  handleUpload,
  showSnackbar,
  handleCloseSnackbar,
  isLoading
}) {
  return (
    <div className="modal-background">
      <CustomSnackbarError
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        message="xlsx 형식의 파일만 업로드해주세요."
      />
      <div
        className="cost-excel-add-modal-view"
        onClick={e => e.stopPropagation()}
      >
        {isLoading &&
          <div className="loading-container">
            <img
              src={loadingSpinner}
              alt="loading"
              className="loading-spinner"
            />
            <div className="loading-text">엑셀 데이터를 서버로 보내는 중입니다...</div>
          </div>}
        <div className="excel-header">
          <p>가격 데이터 추가하기</p>
          <div className="close-button" onClick={onImmediateClose}>
            <img src={xButtonPic} alt="Close" />
          </div>
        </div>
        <div className="excel-body">
          <div
            className={`upload-excel-file ${isDragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <img src={excel2Img} alt="excel2Img" />
            {fileName
              ? <div className="selected-file">
                  선택된 파일: {fileName}
                </div>
              : <p>업로드할 파일을 드래그해주세요</p>}
            {fileName &&
              <button className="upload-button" onClick={handleUpload}>
                전송하기
              </button>}
          </div>

          <div className="description-excel">
            <p className="excel-title">엑셀 파일 예시</p>
            <img src={excelExample2} alt="excelExample2" />
            <p className="margin-side">· 기존에 제공해주신 파일 형태 그대로 작성해주시면 됩니다.</p>
            <p className="margin-side">
              · 파일 이름은 어학원 이름_프로그램 이름 (ex: CCEL_language_learning) 형태로 저장해주셔야
              합니다.
            </p>
            <p className="margin-side">
              · 시트 이름은 각각 tuition, accomodation, extra로 해주시면 됩니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CostExcelAddModalView;
